.splashpage-component {
  #tx-country{
    padding: 2.4rem 1.6rem 0.6rem 5.6rem;

    &-icon{
      position: absolute;
      bottom: 0.6rem;
      left: 1.6rem;
      width: 2.4rem;
      height: 2.4rem;
      display: flex;
      align-items: center;
      align-content: center;
    }
  }

  #tx-languages{
    padding: 2.4rem 1.6rem 0.6rem 5.6rem;

    &-icon{
      position: absolute;
      bottom: 0.6rem;
      left: 1.8rem;
      width: 2.4rem;
      height: 2.4rem;
      display: flex;
      align-items: center;
    }
  }
}